import React, { useState, useMemo, useReducer, useCallback } from 'react'
import { Grid, Stack } from '@mui/material'

import { Edit, Mail, Inventory2 } from '@mui/icons-material'

import ActionsPopover from '@components/common/ActionsPopover'
import MatchInvoicesModal from './MatchInvoicesModal'
import HeaderActions from './HeaderActions'

import { useTranslation } from '@contexts/translation'
import { useFetchData } from '@api/fetchData'
import { useAuth } from '@contexts/auth'
import { checkPerms } from '@utils/checkPerms'

import DeleteModal from '@components/common/DeleteModal'
import { onDeletePayment } from '@api/payments'
import { mutate } from 'swr'
import { useSnackbar } from 'notistack'
import { replaceDotByComma } from '@utils/replaceDotByComma'
import { responseHandler } from '@utils/responseHandler'
import CommonModal from '@components/common/Modal/CommonModal'
import AddPaymentForm from './AddPaymentForm'
import getTotal from '@utils/getTotal'
import API_URL from '@config/services'
import TableTemplate from '@components/common/Table'
import formatURL from '@utils/formatURL'
import { queriesReducer } from '@utils/commonReducerFunctions'
import { formatNumber } from '@utils/formatNumber'
import WrapperBlock from '@components/common/WrapperBlock'
import FiltersElement from '@components/common/Filter/FiltersElement'

const ExtraHeaderBlock = ({
  queriesProps = {},
  operationId,
  downloadData,
  actionsProps,
  permissions
}) => {
  const { dispatchPaymentsQueries, paymentsQueries } = queriesProps

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="flex-end"
    >
      <FiltersElement
        defaultQueries={{}}
        handleQueries={dispatchPaymentsQueries}
        resetPage={() => dispatchPaymentsQueries({ page: 1 })}
        {...(!operationId && { operation: paymentsQueries?.operation || '' })}
        payment_type={paymentsQueries?.type || ''}
        date
      />
      <HeaderActions
        downloadData={downloadData}
        actionsProps={actionsProps}
        permissions={permissions}
      />
    </Stack>
  )
}

const actionsModalInitialState = {
  add: false,
  edit: false,
  delete: false,
  archive: false,
  match: false,
  paymentId: null,
  payment: null
}
const actionsModalReducer = (state, action) => {
  if (action === 'close') return actionsModalInitialState
  else if (action.type)
    return {
      ...actionsModalInitialState,
      [action.type]: true,
      paymentId: action.paymentId ?? null,
      payment: action.payment ?? null
    }
  return state
}

const Payments = ({
  operationId,
  operation = null,
  // leaseId = null,
  tenantId = null
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { t, lang } = useTranslation()
  const { organization, organizationTeam, permissions, orgSettings } = useAuth()

  const [loading, setLoading] = useState(false)

  const [actionsModal, dispatchActionsModal] = useReducer(
    actionsModalReducer,
    actionsModalInitialState
  )
  const [paymentsQueries, dispatchPaymentsQueries] = useReducer(
    queriesReducer,
    { page: 1, page_size: 25 }
  )
  const paymentsURL = formatURL(API_URL.PAYMENTS.GET_PAYMENTS_LIST, {
    ...paymentsQueries,
    ...(operationId && { operation: operationId }),
    ...(tenantId && { tenant: tenantId })
  })
  const { data: paymentsList, isLoading: isLoadingPayments } =
    useFetchData(paymentsURL)

  const manageInvoices = checkPerms({
    perm: ['manage_invoices'],
    org: organization,
    team: organizationTeam,
    ...(operationId && { project: operationId }),
    perms: permissions
  })

  const onClose = () => {
    dispatchActionsModal('close')
  }
  const handleMatchPayment = paymentId => {
    dispatchActionsModal({ type: 'match', paymentId })
  }

  const ACTIONS = useCallback(
    payment => [
      {
        id: 0,
        label: t('edit'),
        icon: <Edit color="secondary" />,
        handleClick: () =>
          dispatchActionsModal({ type: 'edit', payment: payment })
      },
      {
        id: 1,
        label: t('payment_match_with_invoice'),
        icon: <Mail color="secondary" />,
        handleClick: () =>
          dispatchActionsModal({ type: 'match', paymentId: payment.id })
      },
      {
        id: 2,
        label: t('archive'),
        icon: <Inventory2 color="error" />,
        handleClick: () =>
          dispatchActionsModal({ type: 'archive', paymentId: payment.id })
      }
    ],
    [t]
  )

  const currency = useMemo(() => {
    return operation ? operation.currency_display : orgSettings?.currency_symbol
  }, [operation, orgSettings?.currency_symbol])

  const getPaymentType = useCallback(
    type => {
      switch (type) {
        case 'cash':
          return t('payment_type_cash')
        case 'check':
          return t('payment_type_check')
        case 'card':
          return t('payment_type_card')
        case 'lcr':
          return t('payment_type_lcr')
        case 'transfer':
          return t('payment_type_transfer')
        case 'deposit':
          return t('payment_type_deposit')
        case 'rate':
          return t('payment_type_rate')
        default:
          return type
      }
    },
    [t]
  )

  const downloadData = useMemo(
    () =>
      paymentsList?.results
        .map(d => {
          return {
            [t('date')]: d.date,
            [t('type')]: getPaymentType(d.type),
            [t('operation')]: d.operation?.name,
            [t('tenant')]: d.tenant?.display,
            [t('amount')]: replaceDotByComma(d.amount, 2, lang)
          }
        })
        .concat([
          {
            [t('date')]: 'Total ',
            [t('type')]: '',
            [t('operation')]: '',
            [t('tenant')]: '',
            [t('amount')]: replaceDotByComma(
              getTotal(paymentsList?.results, 'amount'),
              2,
              lang
            )
          }
        ]),
    [paymentsList?.results, getPaymentType, t, lang]
  )

  const onConfirmArchive = async () => {
    setLoading(true)
    const response = await onDeletePayment(actionsModal.paymentId)

    await responseHandler({
      response,
      callback: async () => await mutate(paymentsURL),
      t,
      msgSuccess: t('response_message_payment_deleted'),
      snackbar: enqueueSnackbar
    })

    setLoading(false)
    onClose()
  }

  const columns = useMemo(() => {
    return [
      { field: 'date', title: t('date') },
      { field: 'type', title: t('type') },
      {
        field: 'operation',
        title: t('operation'),
        hidden: Boolean(operationId)
      },
      { field: 'tenant', title: t('tenant'), hidden: Boolean(tenantId) },
      {
        field: 'amount',
        title: t('amount'),
        align: 'right',
        sortBy: 'amount_sort'
      },
      {
        field: 'actions',
        title: '',
        width: 0,
        sorting: false,
        hidden: !manageInvoices
      }
    ]
  }, [t, manageInvoices, tenantId, operationId])

  const bodyData = useMemo(() => {
    if (paymentsList?.results) {
      return paymentsList.results.map(payment => {
        return {
          id: payment.id,
          date: payment.date,
          type: getPaymentType(payment.type),
          operation: payment.operation?.name,
          tenant: payment.tenant?.display,
          amount: formatNumber({
            number: payment.amount,
            precision: 2,
            unit: currency
          }),
          amount_sort: payment.amount,
          actions: <ActionsPopover actions={ACTIONS(payment)} />
        }
      })
    }
  }, [paymentsList?.results, currency, getPaymentType, ACTIONS])

  const footerData = useMemo(() => {
    return [
      {
        id: 'total',
        date: t('total'),
        amount: formatNumber({
          number: getTotal(paymentsList?.results, 'amount'),
          precision: 2,
          unit: currency
        })
      }
    ]
  }, [paymentsList?.results, currency, t])

  const containerProps = useMemo(() => {
    return tenantId
      ? {}
      : {
          title: t('payment_title'),
          description: t('payment_description'),
          extraHeaderBlock: (
            <ExtraHeaderBlock
              operationId={operationId}
              downloadData={downloadData}
              actionsProps={{ dispatchActionsModal }}
              permissions={{ manageInvoices }}
              queriesProps={{ paymentsQueries, dispatchPaymentsQueries }}
            />
          )
        }
  }, [
    tenantId,
    operationId,
    downloadData,
    manageInvoices,
    paymentsQueries,
    dispatchPaymentsQueries,
    t
  ])

  const ContainerComponent = useMemo(() => {
    return tenantId ? 'div' : WrapperBlock
  }, [tenantId])

  return (
    <ContainerComponent {...containerProps}>
      <Grid item xs={12}>
        <TableTemplate
          headerProps={{ columns }}
          bodyProps={{
            data: bodyData,
            isLoading: isLoadingPayments || !bodyData
          }}
          footerProps={{ data: footerData }}
          placeholderProps={{
            src: '/Placeholders/Keysy/Payment.png'
          }}
          sortProps={{ enabled: true }}
          paginationProps={{
            enabled: true,
            page: +paymentsQueries.page - 1,
            rowsPerPage: +paymentsQueries.page_size,
            count: paymentsList?.count || 0,
            dispatchQueries: dispatchPaymentsQueries
          }}
        />
      </Grid>

      <CommonModal
        open={actionsModal.add || actionsModal.edit}
        title={
          actionsModal.edit
            ? t('payment_edit_payment')
            : t('payment_add_payment')
        }
        onClose={onClose}
        maxWidth={'sm'}
        isAlignTop
      >
        <AddPaymentForm
          handleClose={onClose}
          project={operation}
          projectId={operationId}
          tenantId={tenantId}
          paymentDetails={actionsModal.payment}
          paymentsURL={paymentsURL}
          handleMatchPayment={handleMatchPayment}
        />
      </CommonModal>

      <MatchInvoicesModal
        handleClose={onClose}
        paymentId={actionsModal.paymentId}
        open={actionsModal.match}
        fetchPaymentsURL={paymentsURL}
        currency={currency}
      />

      <DeleteModal
        open={actionsModal.archive}
        onClose={onClose}
        onConfirm={onConfirmArchive}
        description={t('confirm_delete_payment')}
        loading={loading}
      />
    </ContainerComponent>
  )
}

export default Payments
