import { useEffect, useMemo, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Grid, MenuItem } from '@mui/material'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { format } from 'date-fns'
import { mutate } from 'swr'

import Select from '@components/common/Select'
import DatePicker from '@components/form/DatePicker'
import TextFieldItem from '@components/common/Form/TextFieldItem'

import { useTranslation } from '@contexts/translation'
import API_URL from '@config/services'
import { useFetchData } from '@api/fetchData'
// import { useAuth } from '@contexts/auth'
import { onCreatePayment, updatePayment } from '@api/payments'
import { responseHandler } from '@utils/responseHandler'
import unformatDate from '@utils/unformatDate'
import { LoadingButton } from '@mui/lab'

const AddPaymentForm = ({
  handleClose,
  project,
  projectId,
  tenantId,
  paymentsURL,
  paymentDetails,
  handleMatchPayment
}) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const schema = yup.object().shape({
    operation: yup.string().required(t('validation_required_field')),
    tenant: tenantId
      ? yup.string()
      : yup.string().required(t('validation_required_field')),
    amount: yup.string().required(t('validation_required_field')),
    currency: yup.string(),
    date: yup.date().typeError(t('validation_required_field')),
    description: yup.string(),
    type: yup.string()
  })

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      operation: projectId ?? '',
      tenant: tenantId ?? '',
      amount: '0',
      currency: project?.currency ?? '',
      date: new Date(),
      description: '',
      type: ''
    }
  })

  const watchOperation = watch('operation')

  useEffect(() => {
    if (paymentDetails) {
      setValue('operation', paymentDetails.operation.id || '')
      setValue('tenant', paymentDetails.tenant.id || '')
      setValue('amount', paymentDetails.amount || '0')
      setValue('currency', paymentDetails.currency || '')
      setValue('date', unformatDate(paymentDetails.date))
      setValue('description', paymentDetails.description || '')
      setValue('type', paymentDetails.type || '')
    }
  }, [paymentDetails, setValue])

  const { data: projects } = useFetchData(
    API_URL.PROJECTS.GET_PROJECT_LIST_SIGNED
  )

  const { data: tenants } = useFetchData(
    watchOperation
      ? API_URL.TENANTS.GET_TENANTS_LIST_BY_PROJECT(watchOperation)
      : null
  )

  const { data: currencies } = useFetchData(
    API_URL.CURRENCIES.GET_CURRENCIES_LIST
  )

  const paymentTypes = useMemo(
    () => [
      { label: '----------', value: '' },
      { label: t('payment_type_cash'), value: 'cash' },
      { label: t('payment_type_check'), value: 'check' },
      { label: t('payment_type_card'), value: 'card' },
      { label: t('payment_type_lcr'), value: 'lcr' },
      { label: t('payment_type_transfer'), value: 'transfer' },
      { label: t('payment_type_deposit'), value: 'deposit' },
      { label: t('payment_type_rate'), value: 'rate' }
    ],
    [t]
  )

  const onSubmit = async values => {
    setLoading(true)
    if (values.date) values.date = format(new Date(values.date), 'dd-MM-yyyy')

    let response
    if (paymentDetails) await updatePayment(paymentDetails.id, values)
    else response = await onCreatePayment(null, values)

    responseHandler({
      response,
      callback: async () => {
        handleMatchPayment(response.data.id)
        await mutate(paymentsURL)
      },
      t,
      msgSuccess: paymentDetails
        ? t('response_message_payment_updated')
        : t('response_message_payment_created'),
      snackbar: enqueueSnackbar
    })
    setLoading(false)
    handleClose()
  }

  return (
    <form
      data-cy={'form'}
      style={{ width: '100%' }}
      noValidate
      onSubmit={handleSubmit(onSubmit, err => {
        console.error(err)
      })}
    >
      <Grid container spacing={3}>
        {!projectId && (
          <Grid item xs={12}>
            <Select
              required
              fullWidth
              name="operation"
              label={t('operation')}
              variant="outlined"
              control={control}
              defaultValue={''}
              error={Boolean(errors.operation)}
              errorMessage={errors.operation?.message}
              data_testid="Select-0c7611b7-504f-4845-a51d-d00de7560458"
            >
              {projects?.results.map(({ id, display }) => (
                <MenuItem
                  key={id}
                  value={id}
                  data-testid="MenuItem-97beac11-7b8f-4cf3-a143-71760bf7fc17"
                >
                  {display}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}

        {watchOperation && (
          <>
            {!tenantId && (
              <Grid item xs={12}>
                <Select
                  required
                  fullWidth
                  name="tenant"
                  label={t('tenant')}
                  variant="outlined"
                  control={control}
                  defaultValue={''}
                  error={Boolean(errors.tenant)}
                  errorMessage={errors.tenant?.message}
                  data_testid="Select-a2487496-e42e-4e1d-a8c3-ddad0e2293aa"
                >
                  {tenants &&
                    tenants.results.map(({ id, display }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        data-testid="MenuItem-44168792-a6fd-4697-a60a-eb949946431b"
                      >
                        {display}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <Controller
                name="amount"
                control={control}
                render={props => (
                  <TextFieldItem
                    required
                    placeholder={t('amount')}
                    label={t('amount')}
                    errorMessage={errors.amount?.message}
                    type="number"
                    {...props}
                    data_testid="TextFieldItem-e113e24d-1253-44bf-8b4b-818e78df552c"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Select
                fullWidth
                name="currency"
                label={t('currency')}
                variant="outlined"
                control={control}
                data_testid="Select-46efc7dc-6e99-4ca5-abeb-b1cf66b84922"
              >
                <MenuItem
                  value={''}
                  data-testid="MenuItem-3ddbca77-b46d-43ef-821c-894e58e03bc5"
                >
                  {'----------'}
                </MenuItem>
                {currencies?.results.map(({ id, display }) => (
                  <MenuItem
                    key={id}
                    value={id}
                    data-testid="MenuItem-d0f878a5-67b8-498d-b4b7-843900f09791"
                  >
                    {display}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={12}>
              <DatePicker
                required
                name="date"
                label={t('payment_date_label')}
                variant="outlined"
                fullWidth
                control={control}
                error={Boolean(errors.date)}
                errorMessage={errors.date && errors.date.message}
                data_testid="DatePicker-6c475b02-3077-4aac-9000-0df398b1a4a3"
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Controller
                name="description"
                control={control}
                render={props => (
                  <TextFieldItem
                    multiline
                    placeholder={t('description')}
                    label={t('description')}
                    errorMessage={errors.description?.message}
                    {...props}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Select
                fullWidth
                name="type"
                label={t('payment_type_label')}
                variant="outlined"
                control={control}
                data_testid="Select-5009e8d7-fb2e-4f26-9214-cb4d07982bea"
              >
                {paymentTypes.map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid="MenuItem-97eaba17-302e-4814-adf5-8787eedab419"
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </>
        )}

        <Grid item xs={12} align="right">
          <LoadingButton
            type="submit"
            loading={loading}
            data-testid="Button-5db56ade-672b-4d3a-8cc2-f009fadced35"
          >
            {t('save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  )
}

export default AddPaymentForm
